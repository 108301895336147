@import '../../node_modules/bourbon-neat/app/assets/stylesheets/functions/_new-breakpoint.scss';

// Default values
$grid-columns: 12;
//$visual-grid: true;
//$visual-grid-color: yellow;
//$visual-grid-index: front;
//$visual-grid-opacity: 0.5;

$visual-grid-breakpoints: ();
$max-width: 68em;

// Define your breakpoints
$tablet: new-breakpoint(max-width 879px 9);
$mobile: new-breakpoint(max-width 559px 4);

// these are used in some of the refills components
$large-screen-threshold: 960;
$medium-screen-threshold: 520;

