/*! sanitize.css v3.3.0 | CC0 1.0 Public Domain | github.com/10up/sanitize.css */
/*
 * Normalization
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

audio:not([controls]) {
  display: none;
}

b,
strong {
  font-weight: bolder;
}

button {
  -webkit-appearance: button;
  overflow: visible;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
button:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText;
}

button,
select {
  text-transform: none;
}

details {
  display: block;
}

hr {
  overflow: visible;
}

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
}

input {
  -webkit-border-radius: 0;
}
input[type=button], input[type=reset], input[type=submit] {
  -webkit-appearance: button;
}
input[type=number] {
  width: auto;
}
input[type=search] {
  -webkit-appearance: textfield;
}
input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

main {
  display: block;
}

pre {
  overflow: auto;
}

progress {
  display: inline-block;
}

summary {
  display: block;
}

svg:not(:root) {
  overflow: hidden;
}

template {
  display: none;
}

textarea {
  overflow: auto;
}

[hidden] {
  display: none;
}

/*
 * Universal inheritance
 */
*,
::before,
::after {
  box-sizing: inherit;
}

* {
  font-size: inherit;
  line-height: inherit;
}

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
}

/*
 * Opinionated defaults
 */
* {
  margin: 0;
  padding: 0;
}

*,
::before,
::after {
  border-style: solid;
  border-width: 0;
}

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  touch-action: manipulation;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
}
select::-ms-expand {
  display: none;
}
select::-ms-value {
  color: currentColor;
}

svg {
  fill: currentColor;
}

[aria-busy=true] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

[hidden][aria-hidden=false] {
  clip: rect(0 0 0 0);
  display: inherit;
  position: absolute;
}
[hidden][aria-hidden=false]:focus {
  clip: auto;
}

/*
 * Configurable defaults
 */
* {
  background-repeat: no-repeat;
}

:root {
  background-color: #ffffff;
  box-sizing: border-box;
  color: #000000;
  cursor: default;
  font: 100%/1.5 sans-serif;
}

a {
  text-decoration: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
}

button,
[type=button],
[type=date],
[type=datetime],
[type=datetime-local],
[type=email],
[type=month],
[type=number],
[type=password],
[type=reset],
[type=search],
[type=submit],
[type=tel],
[type=text],
[type=time],
[type=url],
[type=week],
select,
textarea {
  min-height: 1.5em;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
}

nav ol,
nav ul {
  list-style: none;
}

small {
  font-size: 75%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

textarea {
  resize: vertical;
}

::-moz-selection {
  background-color: #b3d4fc;
  color: #ffffff;
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc;
  color: #ffffff;
  text-shadow: none;
}

/* common element styles */
@font-face {
  font-family: "flipfont";
  src: url("../flipfont.woff2") format("woff2"), url("../flipfont.woff") format("woff"), url("../flipfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.ff:before {
  display: inline-block;
  font-family: "flipfont";
  font-style: normal;
  font-weight: normal;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ff-lg {
  font-size: 1.6em;
  line-height: 0.75em;
  vertical-align: -30%;
}

.ff-mu {
  font-size: 1.6em;
  line-height: 0.75em;
  vertical-align: -30%;
  padding-right: 10px;
}

.ff-mu:before {
  vertical-align: baseline;
}

.ff-2x {
  font-size: 2.5em;
}

.ff-3x {
  font-size: 3em;
}

.ff-4x {
  font-size: 4em;
}

.ff-5x {
  font-size: 5em;
}

.ff-fw {
  width: 1.2857142857em;
  text-align: center;
}

.ff-ideaflip-logo:before {
  content: "\e001";
}

.ff-back:before {
  content: "\e004";
}

.ff-front:before {
  content: "\e005";
}

.ff-trash:before {
  content: "\e006";
}

.ff-edit:before {
  content: "\e007";
}

.ff-lasso:before {
  content: "\e008";
}

.ff-rectangle:before {
  content: "\e009";
}

.ff-locked:before {
  content: "\e00a";
}

.ff-unlocked:before {
  content: "\e00b";
}

.ff-scroll-down:before {
  content: "\e00c";
}

.ff-scroll-up:before {
  content: "\e00d";
}

.ff-settings:before {
  content: "\e00e";
}

.ff-ok:before {
  content: "\e00f";
}

.ff-cancel:before {
  content: "\e010";
}

.ff-download:before {
  content: "\e011";
}

.ff-inbox:before {
  content: "\e012";
}

.ff-megaphone:before {
  content: "\e013";
}

.ff-lightbulb:before {
  content: "\e014";
}

.ff-rocket:before {
  content: "\e015";
}

.ff-delete:before {
  content: "\e017";
}

.ff-case:before {
  content: "\e018";
}

.ff-home:before {
  content: "\e019";
}

.ff-undo:before {
  content: "\e01a";
}

.ff-eye:before {
  content: "\e01b";
}

.ff-lnr-mic:before {
  content: "\e01c";
}

.ff-lnr-mic-mute:before {
  content: "\e01d";
}

.ff-bar-chart:before {
  content: "\e01e";
}

.ff-credit-card:before {
  content: "\e01f";
}

.ff-help:before {
  content: "\e020";
}

.ff-invite:before {
  content: "\e021";
}

.ff-heart:before {
  content: "\e022";
}

.ff-cloud-download:before {
  content: "\e023";
}

.ff-phone:before {
  content: "\e024";
}

.ff-hangup:before {
  content: "\e025";
}

.ff-permissions:before {
  content: "\e026";
}

.ff-layers:before {
  content: "\e027";
}

.ff-double-up:before {
  content: "\e028";
}

.ff-double-down:before {
  content: "\e029";
}

.ff-copy:before {
  content: "\e02a";
}

.ff-copy-one:before {
  content: "\e02b";
}

.ff-trash-one:before {
  content: "\e02c";
}

.ff-plus-square:before {
  content: "\e031";
}

.ff-plus-circle:before {
  content: "\e032";
}

.ff-plus:before {
  content: "\e033";
}

.ff-pencil:before {
  content: "\e034";
}

.ff-clock:before {
  content: "\e035";
}

.ff-sort:before {
  content: "\e036";
}

.ff-search:before {
  content: "\e037";
}

.ff-minimise:before {
  content: "\e038";
}

.ff-menu:before {
  content: "\e039";
}

.ff-licences:before {
  content: "\e03a";
}

.ff-signout:before {
  content: "\e03b";
}

.ff-building:before {
  content: "\e03c";
}

.ff-notebook:before {
  content: "\e03d";
}

.ff-info-circle:before {
  content: "\e03e";
}

.ff-wrench:before {
  content: "\e03f";
}

.ff-align-left:before {
  content: "\e040";
}

.ff-align-center:before {
  content: "\e041";
}

.ff-align-right:before {
  content: "\e042";
}

.ff-angle-left:before {
  content: "\e043";
}

.ff-angle-right:before {
  content: "\e044";
}

.ff-minus:before {
  content: "\e045";
}

.ff-map:before {
  content: "\e046";
}

.ff-mouse-click:before {
  content: "\e047";
}

.ff-minimise2:before {
  content: "\e048";
}

.ff-mouse-scroll:before {
  content: "\e049";
}

.ff-film:before {
  content: "\e04a";
}

.ff-film-fill:before {
  content: "\e04b";
}

.ff-pointer:before {
  content: "\e04c";
}

.ff-pointer-fill:before {
  content: "\e04d";
}

.ff-diagonal-arrow:before {
  content: "\e04e";
}

.ff-clockwise:before {
  content: "\e04f";
}

.ff-widdershins:before {
  content: "\e050";
}

.ff-smaller:before {
  content: "\e051";
}

.ff-bigger:before {
  content: "\e052";
}

.ff-resize-rotate:before {
  content: "\e053";
}

.ff-external-link:before {
  content: "\e054";
}

.ff-shape-square:before {
  content: "\e055";
}

.ff-shape-circle:before {
  content: "\e056";
}

.ff-shape-hexagon:before {
  content: "\e057";
}

.ff-shape-diamond:before {
  content: "\e058";
}

.ff-shape-rectangle:before {
  content: "\e059";
}

.ff-shape-card:before {
  content: "\e05a";
}

.ff-upload:before {
  content: "\e05c";
}

.ff-outline {
  position: relative;
}

.ff.ff-outline:before {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  z-index: 1;
}

.ff-outline:after {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-text-stroke: 5px rgba(217, 232, 238, 0.9);
  z-index: 0;
  font-family: "flipfont";
  font-style: normal;
  font-weight: normal;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ff-outline.ff-ideaflip-logo:after {
  content: "\e001";
}

.ff-outline.ff-back:after {
  content: "\e004";
}

.ff-outline.ff-front:after {
  content: "\e005";
}

.ff-outline.ff-trash:after {
  content: "\e006";
}

.ff-outline.ff-edit:after {
  content: "\e007";
}

.ff-outline.ff-lasso:after {
  content: "\e008";
}

.ff-outline.ff-rectangle:after {
  content: "\e009";
}

.ff-outline.ff-locked:after {
  content: "\e00a";
}

.ff-outline.ff-unlocked:after {
  content: "\e00b";
}

.ff-outline.ff-scroll-down:after {
  content: "\e00c";
}

.ff-outline.ff-scroll-up:after {
  content: "\e00d";
}

.ff-outline.ff-settings:after {
  content: "\e00e";
}

.ff-outline.ff-ok:after {
  content: "\e00f";
}

.ff-outline.ff-cancel:after {
  content: "\e010";
}

.ff-outline.ff-download:after {
  content: "\e011";
}

.ff-outline.ff-inbox:after {
  content: "\e012";
}

.ff-outline.ff-megaphone:after {
  content: "\e013";
}

.ff-outline.ff-lightbulb:after {
  content: "\e014";
}

.ff-outline.ff-rocket:after {
  content: "\e015";
}

.ff-outline.ff-delete:after {
  content: "\e017";
}

.ff-outline.ff-case:after {
  content: "\e018";
}

.ff-outline.ff-home:after {
  content: "\e019";
}

.ff-outline.ff-undo:after {
  content: "\e01a";
}

.ff-outline.ff-eye:after {
  content: "\e01b";
}

.ff-outline.ff-lnr-mic:after {
  content: "\e01c";
}

.ff-outline.ff-lnr-mic-mute:after {
  content: "\e01d";
}

.ff-outline.ff-bar-chart:after {
  content: "\e01e";
}

.ff-outline.ff-credit-card:after {
  content: "\e01f";
}

.ff-outline.ff-help:after {
  content: "\e020";
}

.ff-outline.ff-invite:after {
  content: "\e021";
}

.ff-outline.ff-heart:after {
  content: "\e022";
}

.ff-outline.ff-cloud-download:after {
  content: "\e023";
}

.ff-outline.ff-phone:after {
  content: "\e024";
}

.ff-outline.ff-hangup:after {
  content: "\e025";
}

.ff-outline.ff-permissions:after {
  content: "\e026";
}

.ff-outline.ff-layers:after {
  content: "\e027";
}

.ff-outline.ff-double-up:after {
  content: "\e028";
}

.ff-outline.ff-double-down:after {
  content: "\e029";
}

.ff-outline.ff-copy:after {
  content: "\e02a";
}

.ff-outline.ff-copy-one:after {
  content: "\e02b";
}

.ff-outline.ff-trash-one:after {
  content: "\e02c";
}

.ff-outline.ff-plus-square:after {
  content: "\e031";
}

.ff-outline.ff-plus-circle:after {
  content: "\e032";
}

.ff-outline.ff-plus:after {
  content: "\e033";
}

.ff-outline.ff-pencil:after {
  content: "\e034";
}

.ff-outline.ff-clock:after {
  content: "\e035";
}

.ff-outline.ff-sort:after {
  content: "\e036";
}

.ff-outline.ff-search:after {
  content: "\e037";
}

.ff-outline.ff-minimise:after {
  content: "\e038";
}

.ff-outline.ff-menu:after {
  content: "\e039";
}

.ff-outline.ff-licences:after {
  content: "\e03a";
}

.ff-outline.ff-signout:after {
  content: "\e03b";
}

.ff-outline.ff-building:after {
  content: "\e03c";
}

.ff-outline.ff-notebook:after {
  content: "\e03d";
}

.ff-outline.ff-info-circle:after {
  content: "\e03e";
}

.ff-outline.ff-wrench:after {
  content: "\e03f";
}

.ff-outline.ff-align-left:after {
  content: "\e040";
}

.ff-outline.ff-align-center:after {
  content: "\e041";
}

.ff-outline.ff-align-right:after {
  content: "\e042";
}

.ff-outline.ff-angle-left:after {
  content: "\e043";
}

.ff-outline.ff-angle-right:after {
  content: "\e044";
}

.ff-outline.ff-minus:after {
  content: "\e045";
}

.ff-outline.ff-map:after {
  content: "\e046";
}

.ff-outline.ff-mouse-click:after {
  content: "\e047";
}

.ff-outline.ff-minimise2:after {
  content: "\e048";
}

.ff-outline.ff-mouse-scroll:after {
  content: "\e049";
}

.ff-outline.ff-film:after {
  content: "\e04a";
}

.ff-outline.ff-film-fill:after {
  content: "\e04b";
}

.ff-outline.ff-pointer:after {
  content: "\e04c";
}

.ff-outline.ff-pointer-fill:after {
  content: "\e04d";
}

.ff-outline.ff-diagonal-arrow:after {
  content: "\e04e";
}

.ff-outline.ff-clockwise:after {
  content: "\e04f";
}

.ff-outline.ff-widdershins:after {
  content: "\e050";
}

.ff-outline.ff-smaller:after {
  content: "\e051";
}

.ff-outline.ff-bigger:after {
  content: "\e052";
}

.ff-outline.ff-resize-rotate:after {
  content: "\e053";
}

.ff-outline.ff-external-link:after {
  content: "\e054";
}

.ff-outline.ff-shape-square:after {
  content: "\e055";
}

.ff-outline.ff-shape-circle:after {
  content: "\e056";
}

.ff-outline.ff-shape-hexagon:after {
  content: "\e057";
}

.ff-outline.ff-shape-diamond:after {
  content: "\e058";
}

.ff-outline.ff-shape-rectangle:after {
  content: "\e059";
}

.ff-outline.ff-shape-card:after {
  content: "\e05a";
}

.ff-outline.ff-upload:after {
  content: "\e05c";
}

@font-face {
  font-family: EmbeddedTitleFont;
  font-weight: 600;
  font-style: normal;
  font-variant: normal;
  src: url("../../fonts/Montserrat-SemiBold.woff2") format("woff2"), url("../../fonts/Montserrat-SemiBold.woff") format("woff"), url("../../fonts/Montserrat-SemiBold.ttf") format("truetype"), url("../../fonts/Montserrat-SemiBold.otf") format("opentype");
}
@font-face {
  font-family: BodyFont;
  font-weight: 500;
  font-style: normal;
  font-variant: normal;
  src: url("../../fonts/Montserrat-Medium.woff2") format("woff2"), url("../../fonts/Montserrat-Medium.woff") format("woff"), url("../../fonts/Montserrat-Medium.ttf") format("truetype"), url("../../fonts/Montserrat-Medium.otf") format("opentype");
}
@font-face {
  font-family: NoteCondensed;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  src: url("../../fonts/montserrat-condensed.woff2") format("woff2"), url("../../fonts/montserrat-condensed.woff") format("woff"), url("../../fonts/montserrat-condensed.ttf") format("truetype"), url("../../fonts/montserrat-condensed.otf") format("opentype");
}
body {
  font-size: 16px;
  font-family: BodyFont, "Montserrat", sans-serif;
  font-weight: 500;
  letter-spacing: -0.02em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4 {
  font-family: EmbeddedTitleFont, "Montserrat", sans-serif;
  font-weight: 600;
}

h1 {
  font-size: 3em;
  letter-spacing: -0.04em;
}

html {
  box-sizing: border-box;
}

*, *::after, *::before {
  box-sizing: inherit;
}

input, textarea {
  font-weight: 300;
  font-size: 1.25em;
  padding: 2.5px 0.8522727273em;
  border: 1px solid #999;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  display: inline-block;
}
input:disabled, textarea:disabled {
  opacity: 0.5;
  cursor: default;
}

div.input-find {
  position: relative;
  display: inline-block;
}
div.input-find input {
  padding: 0.25ex 0.75em 0.25ex 1.65em;
}
div.input-find span.ff-search {
  position: absolute;
  left: 0.6em;
  top: 1.5ex;
  pointer-events: none;
}

div.input-select {
  position: relative;
  display: inline-block;
  margin-right: 1.45em;
  margin-bottom: 1em;
  position: relative;
  display: inline-block;
}
div.input-select span.ff {
  position: absolute;
  right: 0.8522727273em;
  top: 7.5px;
  pointer-events: none;
}
div.input-select select {
  min-height: 1.8em;
  width: 100%;
  font-weight: 300;
  font-size: 1.25em;
  padding: 2.5px 1.9886363636em 2.5px 0.8522727273em;
  border: 1px solid #999;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  display: inline-block;
}
div.input-select select:invalid {
  color: #a9a9a9;
}
div.input-select select:disabled, div.input-select select:disabled + span {
  opacity: 0.5;
  cursor: default;
}

/**
* Sets width of top level container to be either default (no args to outer-container mixin - which results in a max width of 68em) or 95% of the parent available width if we are below the 68 cutoff
This means there will be a bit of margin left and right for mobile and tablet displays but crucially this should not be done with padding etc else it throws wverything else out

**/
img.avatar {
  width: 25px;
  height: 25px;
  margin-right: 0.5em;
  vertical-align: bottom;
}

/* common element styles */
body {
  font-size: 16px;
  font-family: BodyFont, sans-serif;
  margin: 0;
}

h1, h2, h3, h4 {
  font-family: EmbeddedTitleFont, sans-serif;
  font-weight: normal;
}

h1 {
  font-size: 3em;
  margin-right: 0.12em;
  margin-top: 0;
  letter-spacing: -0.04em;
}
h1 span {
  background-color: #000;
  color: #FFF;
  padding: 0 0.15em 0.1em 0.15em;
}

h2 {
  font-size: 1.5em;
  margin-top: 1.5em;
}

p {
  font-size: 1.1em;
  line-height: 1.2em;
  color: #444;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

ul {
  line-height: 1.2em;
  color: #444;
  list-style: square;
  padding-left: 1em;
}
ul li {
  margin-bottom: 0.5em;
}

sub {
  font-size: 0.833em;
  line-height: 0.85em;
}

a {
  color: #000;
}
a:hover {
  color: #666;
}

hr {
  width: 100%;
  height: 1px;
  margin-top: 1em;
  margin-bottom: 1em;
  border: none;
  background-color: #999;
  clear: both;
}

.button {
  font-size: 1.2em;
  font-family: EmbeddedTitleFont;
  padding: 1.5px 1em;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  border: 2px solid #000;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  display: inline-block;
}
.button:hover {
  background-color: #000;
  color: #fff;
}
.button[disabled] {
  color: #fff;
  border-color: #ddd;
  background-color: #eee;
  cursor: default;
}
.button[disabled]:hover {
  border-color: #ddd;
  background-color: #eee;
}

.full-height {
  height: 100%;
}

div.masthead {
  width: 100%;
  background: #f5f870;
  margin-bottom: 2.617924em;
}
div.masthead div.outer {
  padding: 0.6180469716em;
  margin-bottom: 0px;
  padding: 2.617924em 0.6180469716em;
}
@media (max-width: 71.5789473684em) {
  div.masthead div.outer {
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  div.masthead div.outer::after {
    clear: both;
    content: "";
    display: table;
  }
}
@media (min-width: 71.5789473684em) {
  div.masthead div.outer {
    max-width: 68em;
    margin-left: auto;
    margin-right: auto;
  }
  div.masthead div.outer::after {
    clear: both;
    content: "";
    display: table;
  }
}
div.masthead div.outer.large {
  padding: 6.8535260698em 0.6180469716em;
}
div.masthead.large div.outer {
  padding: 6.8535260698em 0.6180469716em;
}

div.logo {
  text-align: center;
}
div.logo a {
  text-decoration: none;
  color: #000;
  letter-spacing: 1px;
  padding-right: 1px;
  margin-top: 0.4em;
}
div.logo a span {
  background: url("../../img/ideaflip-logo-animated.gif") center no-repeat;
  background-size: 258px 100px;
  padding: 40px 120px;
  position: relative;
}
div.logo a:hover {
  color: #666;
}

.alert {
  padding: 1rem;
}

.alert-warning {
  background-color: lightsalmon;
}

.alert-error {
  background-color: lightcoral;
  color: white;
}

div.header div.outer {
  padding: 0.6180469716em;
  margin-bottom: 0px;
  padding: 0.6180469716em;
}
@media (max-width: 71.5789473684em) {
  div.header div.outer {
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  div.header div.outer::after {
    clear: both;
    content: "";
    display: table;
  }
}
@media (min-width: 71.5789473684em) {
  div.header div.outer {
    max-width: 68em;
    margin-left: auto;
    margin-right: auto;
  }
  div.header div.outer::after {
    clear: both;
    content: "";
    display: table;
  }
}
div.header div.outer div.header-content {
  float: left;
  display: block;
  margin-right: 3.287743293%;
  width: 100%;
}
div.header div.outer div.header-content:last-child {
  margin-right: 0;
}
div.header div.outer div.header-content a.sign-in, div.header div.outer div.header-content button.sliding-menu-button {
  display: block;
  font-size: 0.9em;
  font-family: EmbeddedTitleFont;
  letter-spacing: -0.02em;
  color: #777;
  text-decoration: none;
  text-transform: uppercase;
}
div.header div.outer div.header-content a.sign-in:hover, div.header div.outer div.header-content button.sliding-menu-button:hover {
  color: #444;
}
div.header div.outer div.header-content a.sign-in {
  float: right;
  padding-left: 32px;
}
div.header div.outer div.header-content button.sliding-menu-button {
  float: right;
  border: none;
  background-color: transparent;
  font-size: 0.5em;
  margin-top: -1em;
  padding: 0 0 0 32px;
}
div.header div.outer div.header-content button.sliding-menu-button .ff-menu {
  font-size: 4em;
  display: block;
}
div.header div.outer div.header-content button.sliding-menu-button .ff-menu::before {
  vertical-align: bottom;
}
@media screen and (max-width: 559px) {
  div.header div.outer div.header-content button.sliding-menu-button {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    line-height: 1.5;
    font-size: 0.9em;
    margin-top: 0;
  }
  div.header div.outer div.header-content button.sliding-menu-button .ff-menu {
    margin-left: 0.2em;
    font-size: 1.2em;
    display: inherit;
  }
}

div.main-content {
  padding: 0.6180469716em;
  margin-bottom: 0px;
}
@media (max-width: 71.5789473684em) {
  div.main-content {
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  div.main-content::after {
    clear: both;
    content: "";
    display: table;
  }
}
@media (min-width: 71.5789473684em) {
  div.main-content {
    max-width: 68em;
    margin-left: auto;
    margin-right: auto;
  }
  div.main-content::after {
    clear: both;
    content: "";
    display: table;
  }
}

span.no-wrap {
  white-space: nowrap;
}

.sliding-menu-content {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: auto;
  transform: translateX(280px);
  height: 100%;
  width: 280px;
  transition: all 0.25s linear;
  background: #fbfbfb;
  z-index: 999999;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.sliding-menu-content ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.sliding-menu-content ul li {
  margin-bottom: 0;
  margin-top: 0;
}
.sliding-menu-content li a, .sliding-menu-content li label, .sliding-menu-content li button {
  border-bottom: 1px solid #eee;
  color: #000;
  display: block;
  font-weight: bold;
  padding: 1em;
}
.sliding-menu-content li a:hover, .sliding-menu-content li label:hover, .sliding-menu-content li button:hover {
  background-color: #ccc;
  color: #999;
}
.sliding-menu-content.is-visible {
  transform: translateX(0);
}
.sliding-menu-content .toollink, .sliding-menu-content a.toollink, .sliding-menu-content button.toollink {
  color: black;
  font-family: EmbeddedTitleFont;
  text-transform: uppercase;
  font-size: 1em;
  text-decoration: none;
  white-space: nowrap;
}
.sliding-menu-content button.toollink {
  width: 280px;
  text-align: left;
}
.sliding-menu-content .indented {
  padding-left: 50px;
}
.sliding-menu-content a:hover,
.sliding-menu-content .toollink:hover,
.sliding-menu-content a.toollink:hover,
.sliding-menu-content button.toollink:hover {
  color: #668cb3;
}
.sliding-menu-content .tag-label {
  font-size: 0.9em;
  vertical-align: text-top;
  padding: 0 0.5em;
  background-color: #ff5641;
  border-radius: 0.5em;
  color: white;
}

.menu-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.15s ease-out 0s;
  background: #eee;
  opacity: 0;
  visibility: hidden;
  z-index: 999998;
}
.menu-screen.is-visible {
  opacity: 0.8;
  visibility: visible;
}

.accordion {
  background-color: #f6f6f6;
  border: 0px;
  margin: 0 0 1.2em 0;
  padding: 0;
}
.accordion ul {
  margin: 0;
  padding: 0;
}
.accordion li {
  list-style: none;
}
.accordion li > a, .accordion li > label, .accordion li > button {
  color: black;
  display: block;
  padding: 0.6em 1.618em;
  text-decoration: none;
}
.accordion li > button {
  background-color: #f6f6f6;
  margin-top: 0;
  margin-bottom: 0;
}
.accordion li:last-child {
  border: none;
}
.accordion li:focus, .accordion li:hover {
  background-color: #fbfbfb;
}
.accordion ul.submenu {
  display: none;
}
.accordion ul.submenu li {
  background-color: #e9e9e9;
}
.accordion ul.submenu li > button {
  background-color: #e9e9e9;
}
.accordion ul.submenu li:first-child {
  border-top: 0px;
  box-shadow: inset 0 1px 1px #d9d9d9;
}
.accordion ul.submenu li:focus, .accordion ul.submenu li:hover {
  background-color: #eeeeee;
}
.accordion .is-expanded {
  display: block;
  padding-bottom: 0;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
}

.footer {
  padding: 1em;
  flex-shrink: 0;
  background: #505050;
}
.footer ul li {
  color: white;
}
.footer .container {
  max-width: 1144px;
  margin: auto;
}
.footer .columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.footer .column {
  display: block;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  padding: 0.75rem;
}
.footer .column ul {
  margin-left: 0;
  list-style: none;
}
.footer .content {
  color: #FFF;
  text-align: center;
}
.footer .copyright a {
  color: rgb(245, 247, 120);
  text-transform: uppercase;
}
.footer a {
  color: rgb(186, 166, 255);
  text-transform: none;
}
.footer span.no-wrap {
  white-space: nowrap;
}