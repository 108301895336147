/* common element styles */
body {
    font-size: 16px;
    font-family: BodyFont, sans-serif;
    margin: 0;
}

h1, h2, h3, h4 {
    font-family: EmbeddedTitleFont, sans-serif;
    font-weight: normal;
}

h1 {
    font-size: 3em;
    margin-right:0.12em;
    margin-top:0;
    letter-spacing: -0.04em;
    span {
      background-color:#000;
      color:#FFF;
      padding: 0 0.15em 0.1em 0.15em;

    }
}

h2 {
    font-size: 1.5em;
    margin-top: 1.5em;
}

p {
    font-size: 1.1em;
    line-height: 1.2em;
    color: #444;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

ul {
  line-height: 1.2em;
  color: #444;
  list-style: square;
  padding-left: 1em;

  li {
    margin-bottom: 0.5em;
  }
}

sub {
  font-size: 0.833em;
  line-height: 0.85em;
}

a {
  color: #000;
  &:hover {
    color: #666;
  }
}

hr {
  width: 100%;
  height: 1px;
  margin-top: 1em;
  margin-bottom: 1em;
  border: none;
  background-color: #999;
  clear: both;
}

.button {
  @include button();
}