
@import "flip-grid";


@mixin border-rectangle($width: 1px, $color: #999) {
  border: $width solid $color;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  display: inline-block;
}

@mixin form-element($size: 1.1em, $icon: none) {
  $hmargin: calc($size / 1.1) * 0.75;
  $hmargin-icon: calc($hmargin * 14 / 6);
  font-weight: 300;
  font-size: $size;
  @if $icon == none { padding: 2.5px $hmargin; }
  @else if $icon == left { padding: 2.5px $hmargin 2.5px $hmargin-icon; }
  @else { padding: 2.5px $hmargin-icon 2.5px $hmargin; }
  @include border-rectangle();
}

@mixin form-icon($size: 1.1em, $icon: left) {
  position: relative;
  display: inline-block;

  span.ff {
    position: absolute;
    @if $icon == left { left: calc($size / 1.1) * 0.75; }
    @else { right: calc($size / 1.1) * 0.75; }
    top: 7.5px;
    pointer-events: none;
  }
}


@mixin button($size: 1.2em) {
  font-size: $size;
  font-family: EmbeddedTitleFont;
  padding: 1.5px calc($size / 1.2);
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  @include border-rectangle(2px, #000);
  &:hover {
    background-color: #000;
    color: #fff;
  }
  &[disabled] {
    color: #fff;
    border-color: #ddd;
    background-color: #eee;
    cursor: default;
    &:hover {
      border-color: #ddd;
      background-color: #eee;
    }
  }
}


@mixin select($size: 1.25em) {
  @include form-icon($size, right);

  select {
    min-height:1.8em; 
    width: 100%;
    @include form-element($size, right);
    &:invalid {
      color: #a9a9a9;
    }
    &:disabled, &:disabled + span {
      opacity: 0.5;
      cursor: default;
    }
  }
}

@mixin input($size: 1.25em) {
  @include form-element($size, none);
}

@mixin link() {
  font-family: EmbeddedTitleFont, sans-serif;
  text-transform: uppercase;
}


@mixin div-input-find() {
  position: relative;
  display: inline-block;
  input {
    padding: 0.25ex 0.75em 0.25ex 1.65em;
    //width: 245px;
  }
  span.ff-search {
    position: absolute;
    left: 0.6em;
    top: 1.5ex;
    pointer-events: none;
  }
}

@mixin div-input-select() {
    position: relative;
    display: inline-block;
    margin-right: 1.45em;
    margin-bottom: 1em;
    @include select();
}

input, textarea {
  @include input();
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

div.input-find{
  @include div-input-find();
}

div.input-select{
  @include div-input-select();
}



/**
* Sets width of top level container to be either default (no args to outer-container mixin - which results in a max width of 68em) or 95% of the parent available width if we are below the 68 cutoff
This means there will be a bit of margin left and right for mobile and tablet displays but crucially this should not be done with padding etc else it throws wverything else out

**/
@mixin flip-top-level-container() {

  // this calc just makes sure you apply the breakpoint at arount 71em else between 68-71 you dont get any margin so its the equivalent of keeping 95% width up utnil uou have at least 2.5% margin either side 
  $max-width-em: 68em;
  $when-to-apply: calc(100 / 95) * $max-width-em;
  
  @media (max-width: $when-to-apply)  {
    @include outer-container(95%);
  }

  @media (min-width: $when-to-apply)  {
    @include outer-container();
  }
  padding: modular-scale(-1, 1em, $golden);
  margin-bottom: 0px;
}

@mixin avatar($size: 25px) {
  width: $size;
  height: $size;
  margin-right: 0.5em;
  vertical-align: bottom;
}

img.avatar {
  @include avatar;
}
