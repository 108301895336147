// revised 'global' stylesheet - deprecated, but still in use across the LIVE server

@import "sanitize.scss";

@import 'font/fonts';
@import 'pages/components';
@import 'elements-nu';

.full-height {
  height: 100%;
}

div.masthead {
  width: 100%;
  background: #f5f870;
  margin-bottom: modular-scale(2, 1em, $golden);

  div.outer {
    @include flip-top-level-container;
    padding: modular-scale(2, 1em, $golden) modular-scale(-1, 1em, $golden);
    &.large {
      padding: modular-scale(4, 1em, $golden) modular-scale(-1, 1em, $golden);
    }
  }

  &.large {
    div.outer {
      padding: modular-scale(4, 1em, $golden) modular-scale(-1, 1em, $golden);
    }
  }
}
@import 'logo';

.alert {
  padding: 1rem;
}
.alert-warning {
  background-color: lightsalmon;
}
.alert-error {
  background-color: lightcoral;
  color: white;
}

@import 'header';

div.header {
  div.outer {
    @include flip-top-level-container;
    padding: modular-scale(-1, 1em, $golden);
    div.header-content {
      @include span-columns(12);

      a.sign-in, button.sliding-menu-button {
        @include header-nav-text;
      }
      a.sign-in {
        float: right;
        padding-left: 32px;
      }
      button.sliding-menu-button {
        @include header-menu-button;
        @include media($mobile) {
          @include header-menu-button-mobile;
        }
      }
    }
  }
}

div.main-content {
  @include flip-top-level-container;

}

span.no-wrap {
  white-space: nowrap;
}


$sliding-menu-align: right;
$sliding-menu-screen-opacity: 0.8;
@import 'sliding-menu';

@import 'footer';

